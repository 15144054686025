import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LyricsPage from './pages/LyricsPage';
import LyricsDisplayPage from './pages/LyricsDisplayPage';
import LyricsAddPage from './pages/LyricsAddPage';
import SongsByCategory from './pages/SongsByCategory';
import ArtistsPage from './pages/ArtistsPage';
import AllArtistsPage from './pages/AllArtistsPage';
import LoginPage from './pages/LoginPage';
import { AuthProvider } from './AuthContext';
import LyricsEditPage from './pages/LyricsEditPage';
import Unauthorized from './pages/Unauthorized';
import EditArtistPage from './pages/EditArtist';
import UserDashboard from './pages/UserDashboard';
import NewsEditPage from './pages/NewsEditPage';
import SplashScreen from './pages/SplashScreen';

function App() {
  const [loading, setLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const fadeOutTimer = setTimeout(() => {
      setFadeOut(true);
    }, 3500); // Start fade-out after 3 seconds

    const hideTimer = setTimeout(() => {
      setLoading(false); // Hide splash screen after fade-out completes
    }, 4000); // Total duration including fade-out

    return () => {
      clearTimeout(fadeOutTimer);
      clearTimeout(hideTimer);
    };
  }, []);

  return (
    <AuthProvider>
      <div className="app-container">
        <Router>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/songs' element={<LyricsPage />} />
            <Route path="/songs/:id" element={<LyricsDisplayPage />} />
            <Route path="/edit/songs/:songId" element={<LyricsEditPage />} />
            <Route path="/edit/artists/:artistId" element={<EditArtistPage />} />
            <Route path="/artists/:artistId" element={<ArtistsPage />} />
            <Route path="/artists" element={<AllArtistsPage />} />
            <Route path="/addlyrics" element={<LyricsAddPage />} />
            <Route path="/login" element={<LoginPage signUp={false} />} />
            <Route path="/signup" element={<LoginPage signUp={true} />} />
            <Route path="/news" element={<NewsEditPage />} />
            <Route path="/profile" element={<UserDashboard />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/songs/category/:category" element={<SongsByCategory />} />
          </Routes>
        </Router>
        {loading && <SplashScreen fadeOut={fadeOut} />}
      </div>
    </AuthProvider>
  );
}

export default App;
