import React, { useState, useEffect } from 'react';
import './SplashScreen.css';

const SplashScreen = () => {
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [showLogo, setShowLogo] = useState(false);
    const [fadeOut, setFadeOut] = useState(false);

    const titles = ['NEWS', 'SONGS', 'TUTORIALS', 'STORIES', 'SHOP'];

    useEffect(() => {
        const titleInterval = setInterval(() => {
            if (currentTextIndex < titles.length - 1) {
                setCurrentTextIndex(prev => prev + 1);
            } else {
                clearInterval(titleInterval);
                setShowLogo(true);
            }
        }, 500); // Change title every second

        const logoDisplayTime = 900;

        const fadeOutTimer = setTimeout(() => {
            setFadeOut(true);
        }, logoDisplayTime); // Trigger fade-out after showing logo

        return () => {
            clearInterval(titleInterval);
            clearTimeout(fadeOutTimer);
        };
    }, [currentTextIndex, titles.length]);

    return (
        <div className={`splash-screen ${fadeOut ? 'drawer-up' : ''}`}>
            <div className="drawer-content">
                {!showLogo && <h1 className="splash-title">{titles[currentTextIndex]}</h1>}
                {showLogo && <img src="/images/logotextonly.png" alt="Logo" className="splash-logo" />}
            </div>
        </div>
    );
};

export default SplashScreen;
