import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth"; // Import Firebase Auth
import "./LyricsEditPage.css";
import NavBar from "../components/ui/navbar";
import Footer from "../components/ui/footer";

const db = getFirestore();

const categories = [
  "Worship",
  "Offering",
  "Repentance",
  "Encouraging",
  "Christmas",
  "Marriage",
  "Good Friday",
  "Hope",
  "Gospel",
  "Praise",
  "Kids",
  "Comfort",
  "Correction",
  "Second Coming",
  "Commitment",
  "Easter",
  "Thanksgiving",
  "Prayer",
  "Healing",
  "Faith",
  "Grace",
  "Provision",
  "Peace",
  "Unity",
  "Surrender",
  "Deliverance",
  "Trust",
  "Victory",
  "Creation",
  "Joy",
];

const LyricsEditPage = () => {
  const { songId } = useParams();
  const navigate = useNavigate();
  const [song, setSong] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    artistId: "",
    category: "",
    chords: [],
    lan: "",
    likes: [],
    link: "",
    lyrics: [],
    lyricsen: [],
    scale: "",
    tags: [],
    views: 0,
    newTag: "",
  });

  useEffect(() => {
    const checkAccess = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const userId = user.uid;
        console.log(`User ID: ${userId}`); // Debug log
        const userRef = doc(db, "users", userId);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          console.log(`User Data: ${JSON.stringify(userData)}`); // Debug log
          if (userData.songs && !userData.songs.includes(songId)) {
            console.log("User does not have access to this song."); // Debug log
            navigate("/unauthorized"); // Redirect to an unauthorized page or similar
            return;
          }

          // Fetch the song details
          const songRef = doc(db, "songs", songId);
          const songSnap = await getDoc(songRef);
          if (songSnap.exists()) {
            setSong(songSnap.data());
            setFormData(songSnap.data());
          } else {
            console.log("No such document!");
          }
        } else {
          console.log("No such user document!");
          navigate("/unauthorized"); // Redirect to an unauthorized page or similar
        }
      } else {
        console.log("No user logged in.");
        navigate("/unauthorized"); // Redirect to login if no user is authenticated
      }
    };

    checkAccess();
  }, [songId, navigate]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === 'title' ? value.toLowerCase() : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleArrayChange = (e, index, key) => {
    const { value } = e.target;
    const newArray = [...formData[key]];
    newArray[index] = value;
    setFormData({ ...formData, [key]: newArray });
  };

  const handleChordsToggle = (e) => {
    setFormData({ ...formData, hasChords: e.target.value === "yes" });
  };

  const handleAddTag = () => {
    if (formData.newTag.trim()) {
      setFormData({
        ...formData,
        tags: [...formData.tags, formData.newTag.trim()],
        newTag: "",
      });
    }
  };

  const handleRemoveTag = (index) => {
    setFormData({
      ...formData,
      tags: formData.tags.filter((_, i) => i !== index),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const songRef = doc(db, "songs", songId);
    await updateDoc(songRef, formData);
    navigate(`/songs/${songId}`);
  };

  return (
    <div>
      <NavBar />

      <div className="lyrics-edit-page">
        <h1 className="page-heading" style={{ paddingTop: "20px" }}>
          Edit Lyrics
        </h1>
        {song ? (
          <form onSubmit={handleSubmit}>
            <div>
              <label>Title:</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Artist ID:</label>
              <input
                type="text"
                name="artistId"
                value={formData.artistId}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Category:</label>
              <select
                name="category"
                value={formData.category}
                onChange={handleChange}
              >
                <option value="">Select a category</option>
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>Chords:</label>
              <select
                onChange={handleChordsToggle}
                value={formData.hasChords ? "yes" : "no"}
              >
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
              {formData.hasChords &&
                formData.lyrics.map((lyric, index) => (
                  <div key={index}>
                    <input
                      type="text"
                      value={formData.chords[index] || ""}
                      onChange={(e) => handleArrayChange(e, index, "chords")}
                      placeholder="Add chord"
                    />
                    <input
                      type="text"
                      value={lyric}
                      onChange={(e) => handleArrayChange(e, index, "lyrics")}
                    />
                  </div>
                ))}
            </div>
            <div>
              <label>Language:</label>
              <select name="lan" value={formData.lan} onChange={handleChange}>
                <option value="Telugu">Telugu</option>
                <option value="Hindi">Hindi</option>
                <option value="English">English</option>
                {/* Add more options as needed */}
              </select>
            </div>
            <div>
              <label>Link:</label>
              <input
                type="text"
                name="link"
                value={formData.link}
                onChange={handleChange}
              />
            </div>
            <div className="lyrics-side-by-side">
              <div>
                <label>Lyrics: </label>
                {formData.lyrics.map((lyric, index) => (
                  <input
                    key={index}
                    type="text"
                    value={lyric}
                    onChange={(e) => handleArrayChange(e, index, "lyrics")}
                  />
                ))}
              </div>
              <div>
                <label>English Lyrics:</label>
                {formData.lyricsen.map((lyric, index) => (
                  <input
                    key={index}
                    type="text"
                    value={lyric}
                    onChange={(e) => handleArrayChange(e, index, "lyricsen")}
                  />
                ))}
              </div>
            </div>
            <div>
              <label>Scale:</label>
              <input
                type="text"
                name="scale"
                value={formData.scale}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Tags:</label>
              <div className="tags-container">
                {formData.tags.map((tag, index) => (
                  <span key={index} className="tag-item">
                    {tag}
                    <button
                      type="button"
                      className="remove-tag-button"
                      onClick={() => handleRemoveTag(index)}
                    >
                      &times;
                    </button>
                  </span>
                ))}
                <input
                  type="text"
                  value={formData.newTag}
                  onChange={(e) =>
                    setFormData({ ...formData, newTag: e.target.value })
                  }
                  placeholder="Add a tag"
                  className="tag-input-edit"
                />
                <button
                  type="button"
                  onClick={handleAddTag}
                  className="add-tag-button"
                >
                  Add Tag
                </button>
              </div>
            </div>
            <button type="submit">Update Song</button>
          </form>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default LyricsEditPage;
