export const Colors = {
    white: "#f6f6f6",
    black: "#2f2f2f",
    orange: "#f15f3b",
    offwhite: "#f2f2f2"
}

export const cssVariables = `
  :root {
    --white: ${Colors.white};
    --black: ${Colors.black};
    --orange: ${Colors.orange};
    --offwhite: ${Colors.offwhite};
  }
`;