import NavBar from "../components/ui/navbar";
import "./HomePage.css";
import "./LyricsPage.css";
import "./unauthorized.css";
import Footer from "../components/ui/footer";

const Unauthorized = () => {
  return (
    <div>
  <NavBar />
  <div className="unauthorized-page">
    <div className="unauthorized-logo-text">
      <img
        src="/images/oops.png"
        className="unauthorized-logo"
        alt="logo"
      />
      <p>Sorry, you do not have access to this page.</p>
    </div>
  </div>
  <Footer/>
</div>

  );
};

export default Unauthorized;
