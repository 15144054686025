import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import "./Ranking.css"; // Add your styling here
import { formatNumber } from "./constants";

const capitalize = (text) => {
  return text.replace(/\b\w/g, (char) => char.toUpperCase());
};

const Ranking = ({ userID }) => {
  const [topUsers, setTopUsers] = useState([]);
  const [userRank, setUserRank] = useState(null);
  const db = getFirestore();

  useEffect(() => {
    const fetchUsersAndCalculateViews = async () => {
      const usersCollectionRef = collection(db, "users");
      const songsCollectionRef = collection(db, "songs");
      const newsCollectionRef = collection(db, "news");

      const usersSnapshot = await getDocs(usersCollectionRef);
      const songsSnapshot = await getDocs(songsCollectionRef);
      const newsSnapshot = await getDocs(newsCollectionRef);

      const songsData = songsSnapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data();
        return acc;
      }, {});

      const newsData = newsSnapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data();
        return acc;
      }, {});

      const userViews = usersSnapshot.docs.map((userDoc) => {
        const userData = userDoc.data();
        const userSongs = userData.songs || [];
        const userNews = userData.news || [];

        const totalViews =
          userSongs.reduce((sum, songId) => {
            return sum + (songsData[songId]?.views || 0);
          }, 0) +
          userNews.reduce((sum, newsId) => {
            return sum + (newsData[newsId]?.views || 0);
          }, 0);

        return {
          id: userDoc.id,
          name: userData.name,
          views: totalViews,
          songCount: userSongs.length,
          newsCount: userNews.length,
        };
      });

      userViews.sort((a, b) => b.views - a.views);

      const topTenUsers = userViews.slice(0, 10);
      setTopUsers(topTenUsers);

      const userIndex = userViews.findIndex((user) => user.id === userID);
      if (userIndex !== -1) {
        setUserRank({
          rank: userIndex + 1,
          ...userViews[userIndex],
        });
      } else {
        setUserRank(null);
      }
    };

    fetchUsersAndCalculateViews();
  }, [db, userID]);

  return (
    <div className="ranking-section">
      <div className="ranks-container">
        <ul className="ranking-header">
          <li className="user-item">
            <span className="user-rank">#</span>
            <span className="user-name">Author Name</span>
            <span className="user-views">Views</span>
            <span className="user-songs">No. of Songs</span>
            <span className="user-news">No. of News</span>
          </li>
        </ul>
        <ul>
          {topUsers.map((user, index) => (
            <li
              key={user.id}
              className={`user-item ${index < 3 ? "top-three" : ""}`}
            >
              <span className="user-rank">#{index + 1}</span>
              <span className="user-name">{capitalize(user.name)}</span>
              <span className="user-views">{formatNumber(user.views)}</span>
              <span className="user-songs">{formatNumber(user.songCount)}</span>
              <span className="user-news">{formatNumber(user.newsCount)}</span>
            </li>
          ))}
        </ul>
        {userRank && (
          <div className="user-rank-container">
            <h3>Your Ranking</h3>
            <ul>
              <li className="user-item">
                <span className="user-rank">#{userRank.rank}</span>
                <span className="user-name">{capitalize(userRank.name)}</span>
                <span className="user-views">
                  {formatNumber(userRank.views)}
                </span>
                <span className="user-songs">
                  {formatNumber(userRank.songCount)}
                </span>
                <span className="user-news">
                  {formatNumber(userRank.newsCount)}
                </span>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Ranking;
