import React from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import NavBar from "../components/ui/navbar";
import Footer from "../components/ui/footer";
import "./NewsEditPage.css";


const NewsEditPage = () => {
  
  return (
    <div>
      <NavBar />
      <div>
         
      </div>
      <Footer />
    </div>
  );
};

export default NewsEditPage;
