import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  getDocs,
  deleteDoc,
  setDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import NavBar from "../components/ui/navbar";
import Footer from "../components/ui/footer";
import "./UserProfile.css";
import Ranking from "./Ranking";

const capitalize = (text) => {
  return text.replace(/\b\w/g, (char) => char.toUpperCase());
};

const UserDashboard = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(true);
  const [itemsLoading, setItemsLoading] = useState(true);
  const [songCount, setSongCount] = useState(0);
  const [artistCount, setArtistCount] = useState(0);
  const [newsCount, setNewsCount] = useState(0);
  const [likeCount, setLikeCount] = useState(0);
  const [activeSection, setActiveSection] = useState("Songs");
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsToShow, setItemsToShow] = useState(6);
  const [itemsToShowLikes, setItemsToShowLikes] = useState(6);
  const [artists, setArtists] = useState([]);
  const [visibleArtists, setVisibleArtists] = useState(10);
  const [userID, setuserID] = useState("");
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userDocRef = doc(db, "users", currentUser.uid);
        setuserID(currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          await setDoc(userDocRef, {
            name: currentUser.displayName || currentUser.email.split("@")[0],
            artists: [],
            likes: [],
            news: [],
            songs: [],
          });
        }

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setSongCount(userData.songs ? userData.songs.length : 0);
          setArtistCount(userData.artists ? userData.artists.length : 0);
          setNewsCount(userData.news ? userData.news.length : 0);
          setLikeCount(userData.likes ? userData.likes.length : 0);

          if (userData.artists && userData.artists.length > 0) {
            const artistsCollectionRef = collection(db, "artists");
            const artistsSnapshot = await getDocs(artistsCollectionRef);
            const allArtists = artistsSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            const userArtists = allArtists.filter((artist) =>
              userData.artists.includes(artist.id)
            );

            setArtists(userArtists);
          }
        }

        setUserLoading(false);
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [auth, db, navigate]);

  useEffect(() => {
    const fetchItems = async () => {
      setItemsLoading(true);
      const currentUser = auth.currentUser;

      if (!currentUser) return;

      const userDocRef = doc(db, "users", currentUser.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        let fetchedItems = [];

        switch (activeSection) {
          case "Songs":
            if (userData.songs && userData.songs.length > 0) {
              const songsRef = collection(db, "songs");
              const songDocs = await getDocs(songsRef);
              const songsData = songDocs.docs.reduce((acc, doc) => {
                acc[doc.id] = { id: doc.id, ...doc.data() };
                return acc;
              }, {});

              const artistIds = new Set();
              userData.songs.forEach((songId) => {
                if (songsData[songId]?.artistId) {
                  artistIds.add(songsData[songId].artistId);
                }
              });

              const artistsRef = collection(db, "artists");
              const artistDocs = await getDocs(artistsRef);
              const artistsData = artistDocs.docs.reduce((acc, doc) => {
                acc[doc.id] = doc.data().name;
                return acc;
              }, {});

              fetchedItems = userData.songs.map((songId) => {
                const song = songsData[songId] || {};
                return {
                  ...song,
                  artist: artistsData[song.artistId] || "Unknown Artist",
                };
              });
            }
            break;
          case "Artists":
            fetchedItems = userData.artists || [];
            break;
          case "News":
            fetchedItems = userData.news || [];
            break;
          case "Likes":
            if (userData.likes && userData.likes.length > 0) {
              const songsRef = collection(db, "songs");
              const songDocs = await getDocs(songsRef);
              const songsData = songDocs.docs.reduce((acc, doc) => {
                acc[doc.id] = { id: doc.id, ...doc.data() };
                return acc;
              }, {});

              // Fetch artist IDs
              const artistIds = new Set();
              userData.likes.forEach((likeId) => {
                if (songsData[likeId]?.artistId) {
                  artistIds.add(songsData[likeId].artistId);
                }
              });

              // Fetch artist names
              const artistsRef = collection(db, "artists");
              const artistDocs = await getDocs(artistsRef);
              const artistsData = artistDocs.docs.reduce((acc, doc) => {
                acc[doc.id] = doc.data().name;
                return acc;
              }, {});

              fetchedItems = userData.likes.map((likeId) => {
                const song = songsData[likeId] || {};
                return {
                  ...song,
                  artist: artistsData[song.artistId] || "Unknown Artist",
                };
              });
            }
            break;
          default:
            fetchedItems = [];
        }
        setItems(fetchedItems);
      }

      setItemsLoading(false);
    };

    fetchItems();
  }, [activeSection, auth, db]);

  const handleEdit = (id) => {
    console.log(`Edit item with id ${id}`);
  };

  const handleDelete = async (id) => {
    try {
      // Reference to the song document
      const songDocRef = doc(db, "songs", id);

      // Get the song document to fetch artistId
      const songDoc = await getDoc(songDocRef);
      if (songDoc.exists()) {
        const songData = songDoc.data();
        const artistId = songData.artistId;

        // Delete the song document
        await deleteDoc(songDocRef);

        // Update the artist document to remove the song reference
        if (artistId) {
          const artistDocRef = doc(db, "artists", artistId);
          const artistDoc = await getDoc(artistDocRef);
          if (artistDoc.exists()) {
            const artistData = artistDoc.data();
            const updatedSongs = artistData.songs.filter(
              (songId) => songId !== id
            );
            await updateDoc(artistDocRef, { songs: updatedSongs });
          }
        }

        // Update the current user's document to remove the song reference
        const currentUserDocRef = doc(db, "users", auth.currentUser.uid);
        const currentUserDoc = await getDoc(currentUserDocRef);
        if (currentUserDoc.exists()) {
          const userData = currentUserDoc.data();
          const updatedUserSongs = userData.songs.filter(
            (songId) => songId !== id
          );
          await updateDoc(currentUserDocRef, { songs: updatedUserSongs });
        }

        // Update the 'likes' field for all users in the users collection
        const usersCollectionRef = collection(db, "users");
        const usersSnapshot = await getDocs(usersCollectionRef);
        const batch = writeBatch(db);

        usersSnapshot.forEach((userDoc) => {
          const userData = userDoc.data();
          if (userData.likes && userData.likes.includes(id)) {
            const updatedLikes = userData.likes.filter(
              (likeId) => likeId !== id
            );
            batch.update(userDoc.ref, { likes: updatedLikes });
          }
        });

        await batch.commit();

        // Update local state to remove the song from the UI
        setItems((prevItems) => prevItems.filter((item) => item.id !== id));
      }
    } catch (error) {
      console.error("Error deleting song: ", error);
    }
  };

  const handleUnlike = async (id) => {
    const userDocRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userDocRef);
    const userData = userDoc.data();

    if (userData && userData.likes) {
      const updatedLikes = userData.likes.filter((likeId) => likeId !== id);
      await setDoc(
        userDocRef,
        { ...userData, likes: updatedLikes },
        { merge: true }
      );
      setItems((prevItems) => prevItems.filter((item) => item.id !== id));
    }
  };

  const handleShowMoreLikes = () => {
    setItemsToShowLikes((prev) => prev + 6);
  };

  const filteredLikes = items.filter(
    (item) =>
      (item.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.name?.toLowerCase().includes(searchTerm.toLowerCase())) &&
      activeSection === "Likes"
  );

  const handleShowMore = () => {
    setItemsToShow((prev) => prev + 6);
  };

  const loadMore = () => {
    setVisibleArtists((prev) => prev + 10);
  };

  const filteredItems = items.filter(
    (item) =>
      (item.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.name?.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (activeSection !== "Artists" || item.name) // Ensure filtering is handled correctly for artists
  );

  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Filter artists specifically
  const filteredArtists = artists.filter((artist) =>
    artist.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (userLoading) {
    return (
      <div>
        <NavBar />
        <div className="loading-lyrics-display-page">
          <div className="logo-loading">
            <img
              src="/images/logo512.png"
              className="logo"
              alt="logo"
              style={{ width: "100px" }}
            />
            <p className="song-artist-lyrics-display">Loading...</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div>
      <NavBar />
      <div className="dashboard">
        {user && (
          <div className={`profile-section ${isCollapsed ? "collapsed" : ""}`}>
            <div className="profile-image-display-name">
              <img src={user.photoURL} alt="Profile" className="profile-pic" />
              <h2 className="profile-user-display-name">{user.displayName}</h2>
              {/* <h2 className="profile-user-display-rank">
                Your Ranking <span className="rank-number">#5</span>
              </h2> */}
            </div>
            <ul className="dashboard-menu-items">
              <li
                className={`profile-user-list-item ${
                  activeSection === "Songs" ? "active" : ""
                }`}
                onClick={() => setActiveSection("Songs")}
              >
                <img
                  src={
                    activeSection === "Songs"
                      ? "/images/lyrics-active.png"
                      : "/images/lyrics-inactive.png"
                  }
                  alt="Songs"
                  style={{ width: "20px", marginRight: "10px" }}
                />
                Songs ({songCount})
              </li>
              <li
                className={`profile-user-list-item ${
                  activeSection === "Artists" ? "active" : ""
                }`}
                onClick={() => setActiveSection("Artists")}
              >
                <img
                  src={
                    activeSection === "Artists"
                      ? "/images/mic.png"
                      : "/images/mic-inactive.png"
                  }
                  alt="Artists"
                  style={{ width: "20px", marginRight: "10px" }}
                />
                Artists ({artistCount})
              </li>
              <li
                className={`profile-user-list-item ${
                  activeSection === "News" ? "active" : ""
                }`}
                onClick={() => setActiveSection("News")}
              >
                <img
                  src={
                    activeSection === "News"
                      ? "/images/news.png"
                      : "/images/new-inactive.png"
                  }
                  alt="News"
                  style={{ width: "20px", marginRight: "10px" }}
                />
                News ({newsCount})
              </li>
              <li
                className={`profile-user-list-item ${
                  activeSection === "Likes" ? "active" : ""
                }`}
                onClick={() => setActiveSection("Likes")}
              >
                <img
                  src={
                    activeSection === "Likes"
                      ? "/images/like.png"
                      : "/images/likeg.png"
                  }
                  alt="Likes"
                  style={{ width: "20px", marginRight: "10px" }}
                />
                Likes ({likeCount})
              </li>
              <li
                className={`profile-user-list-item ${
                  activeSection === "Ranking" ? "active" : ""
                }`}
                onClick={() => setActiveSection("Ranking")}
              >
                <img
                  src={
                    activeSection === "Ranking"
                      ? "/images/rank.png"
                      : "/images/rank-inactive.png"
                  }
                  alt="Ranking"
                  style={{ width: "20px", marginRight: "10px" }}
                />
                Ranking
              </li>

              {/* <li
                className={`profile-user-list-item ${
                  activeSection === "Ranking" ? "active" : ""
                }`}
                onClick={() => setActiveSection("Ranking")}
              >
                <img
                  src={
                    activeSection === "Ranking"
                      ? "/images/rank.png"
                      : "/images/rank-inactive.png"
                  }
                  alt="Ranking"
                  style={{ width: "20px", marginRight: "10px" }}
                />
                Logout
              </li> */}
            </ul>
            {/* <button className="collapse-toggle" onClick={toggleCollapse}>
              {isCollapsed ? "Expand" : "Collapse"}
            </button> */}
          </div>
        )}
        <div className="dashboard-content">
          <div className="dashboard-title-search-bar">
            <h2 className="profile-user-dashboard-title">{activeSection}</h2>
            <input
              className="dashboard-search-bar"
              type="text"
              placeholder={`Search ${activeSection.toLowerCase()}...`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {itemsLoading ? (
            <div className="logo-loading-user-dashboard">
              <p className="song-artist-lyrics-display">Loading...</p>
            </div>
          ) : (
            <div className="dashboard-section">
              {activeSection === "Ranking" ? (
                <Ranking userID={userID} />
              ) : (
                <div className="items-grid">
                  {activeSection === "Artists" ? (
                    filteredArtists.length > 0 ? (
                      filteredArtists.slice(0, visibleArtists).map((artist) => (
                        <li key={artist.id} className="artist-item">
                          <Link
                            to={`/artists/${artist.id}`}
                            className="artist-link"
                          >
                            <img
                              src={artist.img}
                              alt={artist.name}
                              className="artist-image"
                            />
                            <span className="artist-name">
                              {capitalize(artist.name)}
                            </span>
                          </Link>
                          <div className="card-actions">
                            <Link
                              to={`/edit/artists/${artist.id}`}
                              className="edit-delete-button"
                            >
                              Edit
                            </Link>
                            {/* <button
                              className="edit-delete-button"
                              onClick={() => handleDelete(artist.id)}
                            >
                              Delete
                            </button> */}
                          </div>
                        </li>
                      ))
                    ) : (
                      <div className="not-available-text">
                        No artists available.
                      </div>
                    )
                  ) : activeSection === "Songs" ? (
                    filteredItems.length > 0 ? (
                      <>
                        <div className="category-song-card-user-profile-add">
                          <Link
                            to="/addlyrics"
                            className="category-card-content"
                          >
                            <div className="plus-symbol">+</div>
                          </Link>
                        </div>
                        {filteredItems.slice(0, itemsToShow).map((item) => (
                          <div
                            className="song-cards-user-dashboard"
                            key={item.id}
                          >
                            <div className="category-song-card-user-profile">
                              <Link
                                to={`/songs/${item.id}`}
                                className="category-card-content"
                              >
                                <h2 className="category-card-song-title">
                                  {capitalize(item.title)}
                                </h2>
                                <p className="category-card-song-artist">
                                  Artist: {capitalize(item.artist)}
                                </p>
                                <p className="category-card-song-views">
                                  Views: {item.views}
                                </p>
                              </Link>
                            </div>
                            <div className="card-actions">
                              <Link
                                to={`/edit/songs/${item.id}`}
                                className="edit-delete-button"
                              >
                                Edit
                              </Link>
                              <button
                                className="edit-delete-button"
                                onClick={() => handleDelete(item.id)}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="not-available-text">
                        No songs available.
                      </div>
                    )
                  ) : activeSection === "Likes" ? (
                    filteredLikes.length > 0 ? (
                      filteredLikes.slice(0, itemsToShowLikes).map((item) => (
                        <div
                          className="song-cards-user-dashboard"
                          key={item.id}
                        >
                          <div className="category-song-card-user-profile">
                            <Link
                              to={`/songs/${item.id}`}
                              className="category-card-content"
                            >
                              <h2 className="category-card-song-title">
                                {capitalize(item.title)}
                              </h2>
                              <p className="category-card-song-artist">
                                Artist: {capitalize(item.artist)}
                              </p>
                              <p className="category-card-song-views">
                                Views: {item.views}
                              </p>
                            </Link>
                          </div>
                          {/* <div className="card-actions">
                            <button
                              className="edit-delete-button"
                              onClick={() => handleUnlike(item.id)}
                            >
                              Unlike
                            </button>
                          </div> */}
                        </div>
                      ))
                    ) : (
                      <div className="not-available-text">
                        No liked items available.
                      </div>
                    )
                  ) : (
                    <div className="not-available-text">
                      No items available.
                    </div>
                  )}
                </div>
              )}

              {activeSection === "Songs" &&
                filteredItems.length > itemsToShow && (
                  <button
                    className="show-more-button-dashboard"
                    onClick={handleShowMore}
                  >
                    Show More
                  </button>
                )}
              {activeSection === "Artists" &&
                visibleArtists < artists.length && (
                  <button
                    className="show-more-button-lyrics"
                    onClick={loadMore}
                  >
                    Show More
                  </button>
                )}
              {activeSection === "Likes" &&
                filteredLikes.length > itemsToShowLikes && (
                  <button
                    className="show-more-button-dashboard"
                    onClick={handleShowMoreLikes}
                  >
                    Show More
                  </button>
                )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UserDashboard;
