import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import imageCompression from "browser-image-compression";
import "./EditArtist.css";
import NavBar from "../components/ui/navbar";
import Footer from "../components/ui/footer";
import { getAuth } from "firebase/auth";

const EditArtistPage = () => {
  const { artistId } = useParams();
  const navigate = useNavigate();
  const [artist, setArtist] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null); // State for image preview
  const [uploadingImage, setUploadingImage] = useState(false);
  const [updatingArtist, setUpdatingArtist] = useState(false);
  const [artistUpdated, setArtistUpdated] = useState("");

  const db = getFirestore();
  const storage = getStorage();
  const auth = getAuth();

  useEffect(() => {
    const checkAccess = async () => {
      const user = auth.currentUser;

      if (user) {
        const userId = user.uid;
        const userRef = doc(db, "users", userId);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          if (userData.artists && !userData.artists.includes(artistId)) {
            navigate("/unauthorized");
            return;
          }

          const artistRef = doc(db, "artists", artistId);
          const artistSnap = await getDoc(artistRef);
          if (artistSnap.exists()) {
            setArtist(artistSnap.data());
          } else {
            navigate("/error");
          }
        } else {
          navigate("/unauthorized");
        }
      } else {
        navigate("/unauthorized");
      }
    };

    checkAccess();
  }, [artistId, navigate, auth, db]);

  const handleImageUpload = async (file) => {
    setUploadingImage(true);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
    };

    let compressedFile;
    try {
      compressedFile = await imageCompression(file, options);
    } catch (error) {
      console.error("Error compressing image: ", error);
      setUploadingImage(false);
      return;
    }

    const imageRef = ref(storage, `artists/${artistId}/${compressedFile.name}`);
    try {
      await uploadBytes(imageRef, compressedFile);
      const imageUrl = await getDownloadURL(imageRef);
      setUploadingImage(false);
      return imageUrl;
    } catch (error) {
      setUploadingImage(false);
      console.error("Error uploading image: ", error);
    }
  };

  const handleUpdateArtist = async () => {
    setUpdatingArtist(true);
    try {
      const artistRef = doc(db, "artists", artistId);

      let updatedArtist = { ...artist };

      if (imageFile) {
        const imageUrl = await handleImageUpload(imageFile);
        updatedArtist.img = imageUrl;
      }

      await updateDoc(artistRef, updatedArtist);
      setArtist(updatedArtist);
      setArtistUpdated("Artist updated successfully!");
    } catch (error) {
      console.error("Error updating artist: ", error);
      setArtistUpdated("Error updating artist.");
    } finally {
      setUpdatingArtist(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArtist((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddToPlaylist = () => {
    setArtist((prev) => ({
      ...prev,
      playlist: [...prev.playlist, ""],
    }));
  };

  const handleRemoveFromPlaylist = (index) => {
    setArtist((prev) => ({
      ...prev,
      playlist: prev.playlist.filter((_, i) => i !== index),
    }));
  };

  const handlePlaylistChange = (index, value) => {
    setArtist((prev) => ({
      ...prev,
      playlist: prev.playlist.map((item, i) => (i === index ? value : item)),
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);

    // Generate image preview
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  if (!artist) {
    return (
      <div className="loading-container">
        <NavBar />
        <div className="loading-lyrics-display-page">
          <div className="logo-loading">
            <img src="/images/logo512.png" className="logo-loading" alt="logo" />
            <p className="song-artist-lyrics-display">Loading...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="edit-artist-page">
        <NavBar />
        <h2 className="unique-edit-artist-heading">Edit Artist</h2>
        <div className="edit-artist-container">
          <div className="unique-edit-artist-image-preview">
            {imagePreview ? (
              <img src={imagePreview} alt="Preview" className="current-artist-image" />
            ) : (
              artist.img && <img src={artist.img} alt="Artist" className="current-artist-image" />
            )}
            <input
              type="file"
              onChange={handleFileChange}
              className="image-upload-input"
            />
            {uploadingImage && <span className="uploading-text">Uploading image...</span>}
          </div>
          <label className="unique-edit-artist-label">
            Name:
            <input
              type="text"
              name="name"
              value={artist.name}
              onChange={handleChange}
              className="unique-edit-artist-input"
            />
          </label>
          <label className="unique-edit-artist-label">
            Bio:
            <textarea
              name="bio"
              value={artist.bio}
              onChange={handleChange}
              className="unique-edit-artist-textarea"
            />
          </label>
          <label className="unique-edit-artist-label">
            Facebook:
            <input
              type="text"
              name="facebook"
              value={artist.facebook}
              onChange={handleChange}
              className="unique-edit-artist-input"
            />
          </label>
          <label className="unique-edit-artist-label">
            Instagram:
            <input
              type="text"
              name="instagram"
              value={artist.instagram}
              onChange={handleChange}
              className="unique-edit-artist-input"
            />
          </label>
          <label className="unique-edit-artist-label">
            Playlist:
            {artist.playlist &&
              artist.playlist.map((item, index) => (
                <div key={index} className="playlist-item-container">
                  <input
                    type="text"
                    value={item}
                    onChange={(e) => handlePlaylistChange(index, e.target.value)}
                    className="playlist-item-input"
                    placeholder="Add playlist link"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveFromPlaylist(index)}
                    className="remove-playlist-button"
                  >
                    Remove
                  </button>
                </div>
              ))}
            <button
              type="button"
              onClick={handleAddToPlaylist}
              className="add-playlist-button"
            >
              Add Playlist Item
            </button>
          </label>
          <label className="unique-edit-artist-label">
            Spotify:
            <input
              type="text"
              name="spotify"
              value={artist.spotify}
              onChange={handleChange}
              className="unique-edit-artist-input"
            />
          </label>
          <label className="unique-edit-artist-label">
            YouTube:
            <input
              type="text"
              name="youtube"
              value={artist.youtube}
              onChange={handleChange}
              className="unique-edit-artist-input"
            />
          </label>
          <button
            onClick={handleUpdateArtist}
            disabled={updatingArtist}
            className="unique-edit-artist-button"
          >
            {updatingArtist ? "Updating Artist..." : "Update Artist"}
          </button>
          {artistUpdated && (
            <span className="unique-edit-artist-update-message">
              {artistUpdated}
            </span>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EditArtistPage;
