import React, { useState } from "react";
import MenuItem from "./menuItem";
import { Colors } from "./colors";
import "./navbar.css"; // Create and import a CSS file for styling
import { Link } from "react-router-dom";

const NavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setMenuOpen(!isMenuOpen);
  };


  return (
    <header className={`navbar ${isMobileMenuOpen ? "expanded" : ""}`}>
      <Link to="/">
        <img
          src="/images/logow.png"
          className="logo"
          alt="logo"
          style={{ width: "150px" }}
        />
      </Link>
      <button className="hamburger" onClick={toggleMobileMenu}>
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
      </button>
      <nav className={isMobileMenuOpen ? "nav-links open" : "nav-links"}>
        <ul>
          <div>
            <li className="exit-icon" onClick={toggleMobileMenu}>
              ×
            </li>
          </div>
          <MenuItem name="NEWS" path="/news" />
          <MenuItem name="SONGS" path="/songs" />
          <MenuItem name="TUTORIALS" path="/" />
          <MenuItem name="STORIES" path="/" />
          <MenuItem name="SHOP" path="/" />
          <Link
            to = '/profile'
            smooth = {true}
          >
          <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className="profile-icon-nav-bar"
            onClick={() => setMenuOpen(!isMenuOpen)}
          >
            <img
    
              src={hover ? "/images/profileo.png" : "/images/profilew.png"}
              alt="Profile Icon"
            />
            
          </div>
          </Link>
          
        </ul>
      </nav>
    </header>
  );
};

export default NavBar;
