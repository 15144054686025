
const categories = [
    "Worship",
    "Offering",
    "Repentance",
    "Encouraging",
    "Christmas",
    "Marriage",
    "Good Friday",
    "Hope",
    "Gospel",
    "Praise",
    "Kids",
    "Comfort",
    "Correction",
    "Second Coming",
    "Commitment",
    "Easter",
    "Thanksgiving",
    "Prayer",
    "Healing",
    "Faith",
    "Grace",
    "Provision",
    "Peace",
    "Unity",
    "Surrender",
    "Deliverance",
    "Trust",
    "Victory",
    "Creation",
    "Joy",
  ];
  
  const scales = [
    "N/A",
    "C Major",
    "C# Major",
    "D Major",
    "D# Major",
    "E Major",
    "F Major",
    "F# Major",
    "G Major",
    "G# Major",
    "A Major",
    "A# Major",
    "B Major",
    "C Minor",
    "C# Minor",
    "D Minor",
    "D# Minor",
    "E Minor",
    "F Minor",
    "F# Minor",
    "G Minor",
    "G# Minor",
    "A Minor",
    "A# Minor",
    "B Minor",
  ];
  
  const chords = [
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#",
    "A",
    "A#",
    "B",
    "Cm",
    "C#m",
    "Dm",
    "D#m",
    "Em",
    "Fm",
    "F#m",
    "Gm",
    "G#m",
    "Am",
    "A#m",
    "Bm",
  ];
  
  const scaleChords = {
    "C Major": ["C", "Dm", "Em", "F", "G", "Am", "Bdim"],
    "G Major": ["G", "Am", "Bm", "C", "D", "Em", "F#dim"],
    "D Major": ["D", "Em", "F#m", "G", "A", "Bm", "C#dim"],
    "A Major": ["A", "Bm", "C#m", "D", "E", "F#m", "G#dim"],
    "E Major": ["E", "F#m", "G#m", "A", "B", "C#m", "D#dim"],
    "B Major": ["B", "C#m", "D#m", "E", "F#", "G#m", "A#dim"],
    "F# Major": ["F#", "G#m", "A#m", "B", "C#", "D#m", "E#dim"],
    "C# Major": ["C#", "D#m", "E#m", "F#", "G#", "A#m", "B#dim"],
    "F Major": ["F", "Gm", "Am", "Bb", "C", "Dm", "Edim"],
    "Bb Major": ["Bb", "Cm", "Dm", "Eb", "F", "Gm", "Adim"],
    "Eb Major": ["Eb", "Fm", "Gm", "Ab", "Bb", "Cm", "Ddim"],
    "Ab Major": ["Ab", "Bbm", "Cm", "Db", "Eb", "Fm", "Gdim"],
    "Db Major": ["Db", "Ebm", "Fm", "Gb", "Ab", "Bbm", "Cdim"],
    "Gb Major": ["Gb", "Abm", "Bbm", "Cb", "Db", "Ebm", "Fdim"],
    "Cb Major": ["Cb", "Dbm", "Ebm", "Fb", "Gb", "Abm", "Bbdim"],
    "A Minor": ["Am", "Bdim", "C", "Dm", "Em", "F", "G"],
    "E Minor": ["Em", "F#dim", "G", "Am", "Bm", "C", "D"],
    "B Minor": ["Bm", "C#dim", "D", "Em", "F#m", "G", "A"],
    "F# Minor": ["F#m", "G#dim", "A", "Bm", "C#m", "D", "E"],
    "C# Minor": ["C#m", "D#dim", "E", "F#m", "G#m", "A", "B"],
    "G# Minor": ["G#m", "A#dim", "B", "C#m", "D#m", "E", "F#"],
    "D# Minor": ["D#m", "E#dim", "F#", "G#m", "A#m", "B", "C#"],
    "A# Minor": ["A#m", "B#dim", "C#", "D#m", "E#m", "F#", "G#"],
    "D Minor": ["Dm", "Edim", "F", "Gm", "Am", "Bb", "C"],
    "G Minor": ["Gm", "Adim", "Bb", "Cm", "Dm", "Eb", "F"],
    "C Minor": ["Cm", "Ddim", "Eb", "Fm", "Gm", "Ab", "Bb"],
    "F Minor": ["Fm", "Gdim", "Ab", "Bbm", "Cm", "Db", "Eb"],
    "Bb Minor": ["Bbm", "Cdim", "Db", "Ebm", "Fm", "Gb", "Ab"],
    "Eb Minor": ["Ebm", "Fdim", "Gb", "Abm", "Bbm", "Cb", "Db"],
    "Ab Minor": ["Abm", "Bbdim", "Cb", "Dbm", "Ebm", "Fb", "Gb"],
  };

  export function formatNumber(num) {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + 'B';
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + 'M';
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + 'K';
    } else {
      return num.toString();
    }
  }